import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["xlink:href"];
import { computed } from 'vue';
export default {
  __name: 'svgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const iconName = computed(() => {
      return `#icon-${props.iconClass}`;
    });
    const svgClass = computed(() => {
      if (props.className) {
        return `svg-icon ${props.className}`;
      }
      return 'svg-icon';
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(svgClass.value),
        "aria-hidden": "true"
      }, [_createElementVNode("use", {
        "xlink:href": iconName.value
      }, null, 8, _hoisted_1)], 2);
    };
  }
};