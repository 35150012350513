import { createI18n } from 'vue-i18n'
// import ZH from './langs/ZH.yml'
// import EN from './langs/EN.yml'
// import JA from './langs/JA.yml'

// 获取浏览器默认语言设置
const getBrowserLanguage = () => {
    const language = 'JA';
    return language.includes('JA') ? 'JA' : 'ZH'
}

// 获取存储的语言设置或使用浏览器默认语言
const getDefaultLanguage = () => {
    return localStorage.getItem('language') || getBrowserLanguage()
}

const i18n = createI18n({
    locale: getDefaultLanguage(),
    fallbackLocale: 'JA',
    messages: {
        JA: {
            condition1: 'ご自身の運用額が{num}USDT以上',
            condition2: '{num}人以上の人を紹介し、各紹介者が任意のデポジットを持つ',
            condition3: 'バイナリー直紹介の少ない方で{num}USDT以上の入金',
            condition4: '条件なし',
            Home: 'ホーム',
            Welcome: 'いらっしゃいませ'
        },
        ZH: {
            condition1: '您的投资额超过{num}USDT',
            condition2: '{num}人以上推荐，每个推荐者持有任意数量的存款',
            condition3: '推荐人数较少的方，入金超过{num}USDT',
            condition4: '无条件',
            Home: '首页',
            Welcome: '欢迎'
        },
        EN: {
            condition1: 'Your investment amount is over {num}USDT',
            condition2: '{num} people recommend, each recommender holds any amount of deposit',
            condition3: 'The side with fewer binary direct referrals, deposit over {num}USDT',
            condition4: 'No conditions',
            Home: 'Home',
            Welcome: 'Welcome'
        }
    }
})

// 添加切换语言的方法
export const setLanguage = (lang) => {
    i18n.global.locale = lang
    localStorage.setItem('language', lang)
    document.querySelector('html').setAttribute('lang', lang.toLowerCase())
}

export default i18n 