import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "card-item"
};
const _hoisted_2 = {
  class: "card-title-text"
};
const _hoisted_3 = {
  class: "card-img"
};
import { ref } from 'vue';
export default {
  __name: 'card',
  props: {
    title: {
      type: String,
      default: ''
    },
    textArr: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const isShow = ref(false);
    const clickJ = () => {
      isShow.value = !isShow.value;
    };
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        onClick: clickJ,
        class: "card-title"
      }, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(__props.title)), 1), _createElementVNode("div", _hoisted_3, [isShow.value ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        class: "i-card",
        "icon-class": "card-ji"
      })) : _createCommentVNode("", true), !isShow.value ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        class: "i-card",
        "icon-class": "card-j"
      })) : _createCommentVNode("", true)])]), isShow.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(isShow.value ? 'card-text' : '')
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.textArr, i => {
        return _openBlock(), _createElementBlock("div", {
          key: i
        }, _toDisplayString(_ctx.$t(i)), 1);
      }), 128))], 2)) : _createCommentVNode("", true)]);
    };
  }
};