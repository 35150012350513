import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-content",
  slot: "content"
};
const _hoisted_2 = {
  class: "label"
};
const _hoisted_3 = {
  class: "label-title"
};
const _hoisted_4 = {
  class: "btn-group flex"
};
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'Hint',
  setup(__props, {
    expose: __expose
  }) {
    const {
      t
    } = useI18n();
    const visible = ref(false);
    const loading = ref(false);
    const form = ref(null);
    const hintText = ref('');
    const formData = ref({
      email: ''
    });
    const rules = {
      email: [{
        required: true,
        message: t('メールアドレスを入力してください'),
        trigger: 'blur'
      }, {
        validator: (rule, value, callback) => {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailRegex.test(value)) {
            callback(new Error(t('正しいメールアドレスを入力してください')));
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }]
    };
    const submitForm = () => {
      visible.value = false;
    };
    const setHintText = text => {
      hintText.value = text;
    };
    watch(visible, newVal => {
      if (newVal) {
        form.value?.resetFields();
      }
    });
    __expose({
      visible,
      setHintText
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: visible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => visible.value = $event),
        modelModifiers: {
          sync: true
        },
        title: _ctx.$t('提示'),
        width: "640px"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_form, {
          model: formData.value,
          rules: rules,
          ref_key: "form",
          ref: form
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(hintText.value), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
            onClick: submitForm,
            loading: loading.value
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('确认')), 1)]),
            _: 1
          }, 8, ["loading"])])]),
          _: 1
        }, 8, ["model"])])]),
        _: 1
      }, 8, ["modelValue", "title"]);
    };
  }
};