import { ElMessage } from 'element-plus';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)



export function toJapaneseTime(time) {
    return dayjs(time).tz('Asia/Tokyo')
}

export function sha256(message) {
    // Create a buffer from the message
    const msgBuffer = new TextEncoder().encode(message);

    // Use the Web Crypto API instead of Node's crypto module
    return crypto.subtle.digest('SHA-256', msgBuffer)
        .then(hashBuffer => {
            // Convert buffer to byte array
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            // Convert bytes to hex string
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            return hashHex;
        });
}


export const interceptAdd = (add, l = 4, k = 4) => {
    return add
        ? `${add?.slice(0, l)}...${add?.slice(-k)}`
        : 'Connect Wallet';
};

// 复制文案
export function copyToClipboard(text) {
    const input = document.createElement('input');
    const commandName = 'copy';
    input.value = text;
    Object.entries({
        opacity: 0,
        position: 'fixed',
        zIndex: -1,
    }).forEach(([key, value]) => {
        input.style[key] = value;
    });
    input.setAttribute('readonly', 'readonly');
    document.body.appendChild(input);
    input.focus();
    input.setSelectionRange(0, input.value.length);
    if (!document.execCommand(commandName, false, undefined)) throw new Error('`document.execCommand` を使用してコピーを実行中にエラーが発生しました');
    input.blur();
    document.body.removeChild(input);
    ElMessage.success('コピーが成功しました');
}

// 千分号方法转换
export const thousandthConversion = (num) => {
    if (!(typeof num === 'number' || typeof num === 'string')) {
        return '--'
    }
    // 处理小数点
    const parts = String(num)?.split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] || '';

    // 只对整数部分添加千分位
    const reg = /\B(?=(\d{3})+$)/g;
    const formattedInteger = integerPart?.replace(reg, ',');

    // 如果有小数部分则拼接回去
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export const screenshotDisplay = (add, l = 4, k = 4) => {
    return add
        ? `${add?.substring(0, l)
        }...${add.substring(add.length - k)}`
        : '--';
};
