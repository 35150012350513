import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue";
import _imports_0 from '@/assets//image/logo.png';
const _hoisted_1 = {
  class: "display-flex flex-justify-content-space-between flex-align-items-center menu"
};
const _hoisted_2 = {
  class: "logo-menu-box"
};
const _hoisted_3 = {
  key: 0,
  class: "menu-box"
};
const _hoisted_4 = {
  class: "menu-z-box"
};
const _hoisted_5 = {
  class: "display-flex flex-align-items-center wallet-box"
};
const _hoisted_6 = {
  class: "funcBtn"
};
const _hoisted_7 = {
  class: "web-wallet"
};
const _hoisted_8 = {
  class: "el-icon is-loading",
  key: 1
};
const _hoisted_9 = {
  key: 1
};
const _hoisted_10 = {
  key: 0,
  class: "el-icon is-loading"
};
const _hoisted_11 = {
  key: 1
};
const _hoisted_12 = {
  key: 0,
  class: "template-page-one web"
};
const _hoisted_13 = {
  class: "main"
};
const _hoisted_14 = {
  class: "link"
};
const _hoisted_15 = ["onClick"];
const _hoisted_16 = {
  class: "text"
};
const _hoisted_17 = {
  key: 0,
  class: "line"
};
const _hoisted_18 = {
  class: "cont"
};
const _hoisted_19 = {
  class: "menuWrap"
};
const _hoisted_20 = {
  class: "menucon"
};
const _hoisted_21 = {
  class: "pageMenu"
};
const _hoisted_22 = {
  class: "title"
};
const _hoisted_23 = ["onClick"];
import Hint from './Hint.vue';
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { setLanguage } from '@/i18n/index';
import useStateStore from '@/stores/index';
import { interceptAdd, copyToClipboard } from '@/utils/utils';
import useWallet from '@/use/useWallet';
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';
import { useAppKitEvents, useWalletInfo } from '@reown/appkit/vue';
export default {
  __name: 'Header',
  setup(__props) {
    const events = useAppKitEvents();
    const walletInfo = useWalletInfo();
    console.log('events', events);
    console.log('walletInfo', walletInfo);
    const hint = ref(null);
    const stateStore = useStateStore();
    const wallet = useWallet();
    const router = useRouter();
    const route = useRoute();
    const baseImgUrl = ref(window.localStorage.getItem('baseImgUrl'));
    const mobileMenu = ref(false);
    const loading = ref(false);
    const footerLink = ref([{
      title: 'ホームページ',
      goLink: () => {
        router.push('/');
        handleClose();
      }
    }, {
      title: 'NISA BANKについて',
      goLink: () => {
        router.push('/about');
        handleClose();
      }
    }, {
      title: '運用戦略',
      goLink: () => {
        router.push('/managementStrategy');
        handleClose();
      }
    }, {
      title: '報酬 & ビジネスプラン',
      goLink: () => {
        router.push('/rewards');
        handleClose();
      }
    }, {
      title: 'よくある質問',
      goLink: () => {
        router.push('/faq');
        handleClose();
      }
    }
    // {
    //   title: 'お問い合わせ',
    //   goLink: () => {
    //     router.push('/inquiry')
    //     handleClose()
    //   },
    // },
    ]);
    const currentRoute = computed(() => {
      return pathArr => {
        return pathArr.map(p => p.toLowerCase()).includes(route.path.toLowerCase());
      };
    });
    const handleClose = () => {
      mobileMenu.value = false;
      closeHidden();
    };
    const openMenu = () => {
      mobileMenu.value = !mobileMenu.value;
      closeHidden();
    };
    const closeHidden = () => {
      const mo = function () {
        // false
      };
      document.addEventListener('touchmove', mo, false);
    };
    const menuClick = path => {
      mobileMenu.value = !mobileMenu.value;
      handleClose();
      router.push(path);
    };
    const handleOpen = () => {
      router.push('/');
    };
    watch(mobileMenu, n => {
      document.body.style.overflow = n ? 'hidden' : '';
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_Login = _resolveComponent("Login");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['flex-justify-content-space-between flex-align-items-center header', !_unref(stateStore).token ? 'isLogin' : ''])
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push('/')),
        class: _normalizeClass(!_unref(stateStore).token ? 'logo' : 'logo login-success')
      }, [_createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(wallet).oNListening && _unref(wallet).oNListening(...args)),
        src: _imports_0,
        alt: ""
      })], 2), _unref(stateStore).token ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: _normalizeClass(currentRoute.value(['/myInvestment']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push('/myInvestment'))
      }, _toDisplayString(_ctx.$t('私の投資')), 3), _createElementVNode("div", {
        class: _normalizeClass(currentRoute.value(['/myTeam']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push('/myTeam'))
      }, _toDisplayString(_ctx.$t('私のチーム')), 3), _createElementVNode("div", {
        class: _normalizeClass(currentRoute.value(['/investment']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[4] || (_cache[4] = $event => _ctx.$router.push('/investment'))
      }, _toDisplayString(_ctx.$t('投資')), 3), _createElementVNode("div", {
        class: _normalizeClass(currentRoute.value(['/notify']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[5] || (_cache[5] = $event => _ctx.$router.push('/notify'))
      }, _toDisplayString(_ctx.$t('通知')), 3)])])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_unref(stateStore).token ? (_openBlock(), _createBlock(_unref(ElDropdown), {
        key: 0,
        trigger: "click",
        placement: "top",
        class: "mobileAccount"
      }, {
        dropdown: _withCtx(() => [_createVNode(_unref(ElDropdownMenu), {
          class: "accountMneu"
        }, {
          default: _withCtx(() => [_createVNode(_unref(ElDropdownItem), {
            class: "border-bottom-1",
            onClick: _unref(wallet).disconnectWallet
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('切断')), 1)]),
            _: 1
          }, 8, ["onClick"]), _createVNode(_unref(ElDropdownItem), {
            onClick: _cache[6] || (_cache[6] = $event => _unref(copyToClipboard)(_unref(stateStore).account))
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('コピー')), 1)]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("p", _hoisted_7, [_createVNode(_component_svg_icon, {
          class: "wallet-icon",
          "icon-class": "wallet-icon"
        }), _unref(stateStore).loading ? (_openBlock(), _createElementBlock("i", _hoisted_8, _cache[12] || (_cache[12] = [_createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 1024 1024"
        }, [_createElementVNode("path", {
          fill: "currentColor",
          d: "M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32m0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32m448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32m-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32M195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0m-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"
        })], -1)]))) : _createCommentVNode("", true), !_unref(stateStore).loading ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_unref(interceptAdd)(_unref(stateStore).account)), 1)) : _createCommentVNode("", true)])]),
        _: 1
      })) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["web-wallet cursor-pointer", {
          'is-loading': _unref(stateStore).loading
        }]),
        onClick: _cache[7] || (_cache[7] = $event => _unref(wallet).connectWallet(hint.value))
      }, [_createVNode(_component_svg_icon, {
        class: "wallet-icon",
        "icon-class": "wallet-icon"
      }), _unref(stateStore).loading ? (_openBlock(), _createElementBlock("i", _hoisted_10, _cache[13] || (_cache[13] = [_createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 1024 1024"
      }, [_createElementVNode("path", {
        fill: "currentColor",
        d: "M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32m0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32m448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32m-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32M195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0m-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"
      })], -1)]))) : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('ウォレットを接続する')), 1))], 2))]), _createVNode(_component_svg_icon, {
        class: "h5 menu-svg",
        "icon-class": "menu",
        onClick: openMenu
      })])]), !_unref(stateStore).token ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(footerLink.value, (i, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [_createElementVNode("div", {
          class: "link-item",
          onClick: i.goLink
        }, [_createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(i.title)), 1)], 8, _hoisted_15), index + 1 !== footerLink.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_17)) : _createCommentVNode("", true)], 64);
      }), 128))])])])) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass(['flex-direction-row flex-justify-content-flex-end h5', 'mobileMenu', {
          isActive: mobileMenu.value
        }])
      }, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("ul", null, [_createElementVNode("li", _hoisted_22, [_cache[14] || (_cache[14] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("div", {
        onClick: _withModifiers(handleClose, ["stop"])
      }, "X")]), _cache[15] || (_cache[15] = _createElementVNode("div", {
        class: "line"
      }, null, -1)), _unref(stateStore).isWallet ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("li", {
        class: _normalizeClass(currentRoute.value(['/myInvestment']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[8] || (_cache[8] = $event => (menuClick('/myInvestment'), mobileMenu.value = false))
      }, _toDisplayString(_ctx.$t('私の投資')), 3), _createElementVNode("li", {
        class: _normalizeClass(currentRoute.value(['/myTeam']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[9] || (_cache[9] = $event => (menuClick('/myTeam'), mobileMenu.value = false))
      }, _toDisplayString(_ctx.$t('私のチーム')), 3), _createElementVNode("li", {
        class: _normalizeClass(currentRoute.value(['/investment']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[10] || (_cache[10] = $event => (menuClick('/investment'), mobileMenu.value = false))
      }, _toDisplayString(_ctx.$t('投資')), 3), _createElementVNode("li", {
        class: _normalizeClass(currentRoute.value(['/notify']) ? 'menu-z-item menu-z-item-active' : 'menu-z-item'),
        onClick: _cache[11] || (_cache[11] = $event => (menuClick('/notify'), mobileMenu.value = false))
      }, _toDisplayString(_ctx.$t('通知')), 3)], 64)) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(footerLink.value, (i, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => (i.goLink(), mobileMenu.value = false)
        }, _toDisplayString(_ctx.$t(i.title)), 9, _hoisted_23);
      }), 128))])])])])])], 2), _createVNode(_component_Login, {
        ref: "login"
      }, null, 512), _createVNode(Hint, {
        ref_key: "hint",
        ref: hint
      }, null, 512)], 2);
    };
  }
};