import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getAccountInfo as getAccountInfoApi, getCoinConfigInfo } from '@/api/api';

export default defineStore('user', () => {
    const accountInfo = ref({
        username: '',
        balance: 0,
        email: '',
        status: '',
        address: '',
    });

    const coinSymbolArray = ref(['USDT', 'eNISA'])

    const loading = ref(false)

    const setLoading = (isLoading) => {
        loading.value = isLoading
    }
    const getAccountInfo = () => { // 
        if (!accountInfo.value.address) {
            setLoading(true)
        }
        getAccountInfoApi({}).then((r) => {
            accountInfo.value = r;
        }).finally(() => {
            if (account.value) {
                setLoading(false)
            }
        })
    };

    const coinSelect = ref([]);

    const getCoinSelect = (type) => {
        getCoinConfigInfo({ type }).then((r) => {
            coinSelect.value = r;
        });
    };

    const isWallet = ref(localStorage.getItem('Token') ? true : false);

    const setIsWallet = (value) => {
        isWallet.value = value;
    };

    const language = ref('ja-JP');

    const setLanguage = (value) => {
        language.value = value;
    };

    const account = ref('');

    const setAccount = (value) => {
        account.value = value;
    };


    const token = ref(localStorage.getItem('Token'));
    const setToken = (value) => {
        token.value = value;
    };

    const nisaConnect = ref(sessionStorage.getItem('nisaConnect'));

    const setNisaConnect = (value) => {
        nisaConnect.value = value;
    };

    return {
        accountInfo,
        getAccountInfo,
        coinSelect,
        getCoinSelect,
        isWallet,
        setIsWallet,
        language,
        setLanguage,
        account,
        setAccount,
        token,
        setToken,
        nisaConnect,
        setNisaConnect,
        setLoading,
        loading,
        coinSymbolArray
    };
});
