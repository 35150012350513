import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
const _hoisted_2 = {
  style: {
    "height": "60px",
    "width": "60px"
  }
};
import { watch } from 'vue';
import useStateStore from '@/use/useStateStore';
import useWallet from '@/use/useWallet';
export default {
  __name: 'App',
  setup(__props) {
    const wallet = useWallet();
    const stateStore = useStateStore();
    const accountData = wallet.getAccount();
    watch([accountData.value, stateStore.accountInfo.value, stateStore.isWallet.value], () => {
      if (stateStore.isWallet.value && stateStore.accountInfo.value.address && accountData.value.address) {
        if (accountData.value.address !== stateStore.accountInfo.value.address) {
          wallet.disconnectWallet();
        } else {
          stateStore.setIsWallet(true);
          stateStore.setAccount(accountData.value.address);
          stateStore.setLoading(false);
        }
      }
    }, {
      immediate: true
    });
    watch(stateStore.isWallet.value, () => {
      if (stateStore.isWallet.value) {
        stateStore.getAccountInfo();
      }
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_backtop = _resolveComponent("el-backtop");
      const _component_Hint = _resolveComponent("Hint");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view), _createVNode(_component_el_backtop, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_svg_icon, {
          style: {
            "width": "60px",
            "height": "60px"
          },
          "icon-class": "up"
        })])]),
        _: 1
      }), _createVNode(_component_Hint, {
        ref: "hint"
      }, null, 512)]);
    };
  }
};