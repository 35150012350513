

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('@/Main.vue'),
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('@/views/Home/Index.vue'),
                meta: {
                    title: 'Home'
                }
            },
            {
                path: '/about',
                name: 'About',
                component: () => import('@/views/About.vue'),
                meta: { title: 'NISA BANKについて' }
            },
            {
                path: '/managementStrategy',
                name: 'ManagementStrategy',
                component: () => import(/* webpackChunkName: "ManagementStrategy" */ '@/views/ManagementStrategy.vue'),
                meta: { title: '運用戦略', role: 'ManagementStrategy', isShow: true, requiresAuth: false }
            },
            {
                path: '/rewards',
                name: 'Rewards',
                component: () => import(/* webpackChunkName: "Rewards" */ '@/views/Rewards.vue'),
                meta: { title: '報酬 & ビジネスプラン', role: 'Rewards', isShow: true, requiresAuth: false }
            },
            {
                path: '/faq',
                name: 'FAQ',
                component: () => import(/* webpackChunkName: "FAQ" */ '@/views/FAQ.vue'),
                meta: { title: 'よくある質問', role: 'FAQ', isShow: true, requiresAuth: false }
            },
            {
                path: '/inquiry',
                name: 'Inquiry',
                component: () => import(/* webpackChunkName: "Inquiry" */ '@/views/Inquiry.vue'),
                meta: { title: '咨询', role: 'Inquiry', isShow: true, requiresAuth: false }
            },
            {
                path: '/termsOfService',
                name: 'TermsOfService',
                component: () => import(/* webpackChunkName: "TermsOfService" */ '@/views/TermsOfService.vue'),
                meta: { title: '利用規約', role: 'TermsOfService', isShow: true, requiresAuth: false }
            },
            {
                path: '/privacyPolicy',
                name: 'PrivacyPolicy',
                component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue'),
                meta: { title: 'プライバシーポリシー', role: 'PrivacyPolicy', isShow: true, requiresAuth: false }
            },
            {
                path: '/myInvestment',
                name: 'myInvestment',
                component: () => import(/* webpackChunkName: "MyInvestment" */ '@/views/MyInvestment/index.vue'),
                meta: { title: '私の投資', role: 'myInvestment', isShow: true, requiresAuth: false }
            },
            {
                path: 'myTeam',
                name: 'myTeam',
                component: () => import(/* webpackChunkName: "MyTeam" */ './views/MyTeam/index.vue'),
                meta: { title: '私のチーム' },
            },
            {
                path: 'investment',
                name: 'investment',
                component: () => import(/* webpackChunkName: "investment" */ './views/Investment/index.vue'),
                meta: { title: '投資' },
            },
            {
                path: 'notify',
                name: 'notify',
                component: () => import(/* webpackChunkName: "MyTeam" */ './views/Notify/index.vue'),
                meta: { title: '通知する' },
            },
            {
                path: 'investmentInfo',
                name: 'investmentInfo',
                component: () => import(/* webpackChunkName: "investmentInfo" */ './views/Investment/Info.vue'),
                meta: { title: '投資情報' },
            },
            {
                path: 'bindEmail',
                name: 'bindEmail',
                component: () => import(/* webpackChunkName: "MyTeam" */ './views/BindEmail.vue'),
                meta: { title: 'メールをバインド' },
            },
            {
                path: 'hint',
                name: 'hint',
                component: () => import(/* webpackChunkName: "MyTeam" */ './views/FormT.vue'),
                meta: { title: 'ヒント' },
            },
            {
                path: 'serviceSuspension',
                name: 'ServiceSuspension',
                component: () => import(/* webpackChunkName: "ServiceSuspension" */ './views/ServiceSuspension.vue'),
                meta: { title: 'よくある質問' },
            },
            {
                path: 'authorizationData/:changeToken/:token',
                name: 'authorizationData',
                component: () => import(/* webpackChunkName: "authorizationData" */ './views/AuthorizationData/index.vue'),
                meta: { title: '承認データ' },
            },
        ]
    }
]

export default routes