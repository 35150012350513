import { storeToRefs } from 'pinia';
import state from '@/stores/index';

export default function useStateStore() { // 按钮权限列表获取
    const stateStore = storeToRefs(state());

    const store = state()

    console.log(124, store);

    return {
        ...store,
        ...stateStore,
    };
}
