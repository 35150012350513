import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes';
import i18n, { setLanguage } from '@/i18n/index';

const { t } = i18n.global;

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    return { top: 0 };
  },
});

const routerNext = {
  setLanguage({ meta }) {
    const { lang = i18n.global.locale.value } = meta;
    if (lang) setLanguage(lang);
    return Promise.resolve(true);
  },
  setTitle({ meta }) {
    console.log('routerNext.setTitle')
    const { title } = meta;
    window.document.title = typeof title === 'function' ? t(title()) : t(title);
    console.log('title', title)
    return Promise.resolve(true);
  },
  setRequiresAuth({ meta }) {
    const { requiresAuth } = meta;
    return Promise.resolve(true);
  },
  setRedirect({ meta }) {
    const { redirection } = meta;
    if (redirection) return Promise.resolve(typeof redirection === 'function' ? redirection.apply(router, arguments) : redirection);
    return Promise.resolve(true);
  },
};

router.beforeEach((to, from) => Promise.all(
  Object.values(routerNext).map(func => func(to, from)),
).then((response) => {
  // console.log(156, localStorage.getItem('Token'))

  if (!localStorage.getItem('Token')) {
    if (
      ['ServiceSuspension', 'rewards', 'home', 'bindEmail', 'about', 'managementStrategy', 'termsOfService', 'privacyPolicy', 'inquiry', 'hint', 'faq'].map(item => item.toLowerCase())
        .includes(to.name?.toLowerCase())
    ) {
      return true;
    }
    return { name: 'home' };
  }

  const path = [...response].reverse().find(_path => _path && typeof _path === 'string');

  if (path) {
    return { path, replace: true };
  }
  if (response.some(isNext => !isNext)) {
    return false;
  }
  return true;
}).catch(error => {
  console.error(error);
  return false;
}));


export const route = [
  'params',
  'query',
  'path',
  'name',
  'meta',
  'matched',
  'hash',
  'fullPath',
].reduce(
  (acc, cur) => Object.defineProperty(acc, cur, { get: () => router.currentRoute.value[cur] }) || acc,
  Object.create(null),
);

export default router;
