import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 如果需要中文界面，还需要引入中文语言包
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import ja from 'element-plus/dist/locale/ja.mjs'
import i18n from '@/i18n/index'
import MyTree from '@/views/MyTeam/components/MyThee.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import FAQ from '@/components/FAQ.vue';
import '@/assets/css/common.less';
import '@/assets/css/colors.less';
import '@/assets/css/pageView.less';
import '@/assets/css/global.css';
import './assets/font/iconfont.css';
import './assets/css/media.less';
import 'element-plus/dist/index.css'
import 'element-plus/dist/index.css'
import SvgIcon from '@/components/svgIcon'; // svg组件
import router from '@/router/router-setup';



const app = createApp(App)
app.use(createPinia())

// 根据当前语言设置Element Plus的语言
const elementLocale = i18n.global.locale.value === 'zh' ? zhCn : ja

app.use(ElementPlus, {
    locale: elementLocale,
})





// 注册到全局
app.component('svg-icon', SvgIcon);
const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context('@/icons/svg', false, /\.svg$/); // Changed path to @/assets/svg
requireAll(req);

app.component('MyTree', MyTree);
app.component('Header', Header);
app.component('Footer', Footer);
app.component('FAQ', FAQ);

app.use(i18n)
app.use(router)
app.mount('#app')

