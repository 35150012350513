import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/image/footer-logo.png';
const _hoisted_1 = {
  class: "footer"
};
const _hoisted_2 = {
  class: "footer-one"
};
const _hoisted_3 = {
  class: "main"
};
const _hoisted_4 = {
  class: "link"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "text"
};
const _hoisted_7 = {
  key: 0,
  class: "line"
};
const _hoisted_8 = {
  class: "copyright"
};
import { useRouter } from 'vue-router';
export default {
  __name: 'Footer',
  setup(__props) {
    const router = useRouter();
    const footerLink = [{
      title: 'ホームページ',
      goLink: () => {
        router.push('/');
      }
    }, {
      title: 'NISA BANKについて',
      goLink: () => {
        router.push('/about');
      }
    }, {
      title: '運用戦略',
      goLink: () => {
        router.push('/managementStrategy');
      }
    }, {
      title: '報酬 & ビジネスプラン',
      goLink: () => {
        router.push('/rewards');
      }
    }, {
      title: 'よくある質問',
      goLink: () => {
        router.push('/faq');
      }
    },
    // {
    //   title: 'お問い合わせ',
    //   goLink: () => {
    //     router.push('/inquiry');
    //   },
    // },
    {
      title: '利用規約',
      goLink: () => {
        router.push('/termsOfService');
      }
    }, {
      title: 'プライバシーポリシー',
      goLink: () => {
        router.push('/privacyPolicy');
      }
    }];
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push('/')),
        class: "logo"
      }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)])), _createElementVNode("div", _hoisted_4, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(footerLink, (i, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [_createElementVNode("div", {
          class: "link-item",
          onClick: i.goLink
        }, [_createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(i.title)), 1)], 8, _hoisted_5), index + 1 !== footerLink.length ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("", true)], 64);
      }), 64))])]), _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('© NISA BANK, Ltd. All rights reserved.')), 1)])]);
    };
  }
};