import { createAppKit, useAppKit, useAppKitProvider, useAppKitState, useAppKitAccount, useDisconnect, useAppKitTheme } from '@reown/appkit/vue'
import { bscTestnet, mainnet, bsc } from '@reown/appkit/networks'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { ethers } from 'ethers';
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { clientCode, login } from '@/api/api'
import useStateStore from '@/use/useStateStore';
import { useRouter } from 'vue-router';
import { signMessage } from '@wagmi/core'
import { ElMessage } from 'element-plus'
import { funcContract as funcContractAddress } from '@/utils/constant'


export default function useWallet() {
    console.log('useWallet')
    const stateStore = useStateStore();
    const router = useRouter();

    // 1. Get projectId from https://cloud.reown.com
    const projectId = '4eea2795f2d058a9cdf4d64e7e04d2bc'

    // 2. Create a metadata object
    const metadata = {
        name: 'eNISA',
        description: 'eNISA',
        url: location.origin, // origin must match your domain & subdomain
        icons: [location.origin + '/favicon.ico']
    }


    // 4. Create Wagmi Adapter
    const wagmiAdapter = new WagmiAdapter({
        // networks: [bscTestnet, mainnet],
        networks: [bsc],
        projectId,
    })


    const config = wagmiAdapter.wagmiConfig

    // 5. Create the modal
    const modal = createAppKit({
        adapters: [wagmiAdapter],
        networks: [bsc],
        projectId,
        metadata,
        features: {
            analytics: true, // Optional - defaults to your Cloud configuration
            email: false,
            socials: false,
            emailShowWallets: false,
        },
        tokens: {
            "eip155:97": {
                address: '0x8dE8352E3a15487193e166F4bCdEC939Bd2b883d', // 转笔合约地址
            },
        },
        featuredWalletIds: [
            'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
        ],
        connectorImages: {
            metaMask: 'https://images.mydapp.com/metamask.png',
            coinbaseWallet: 'https://images.mydapp.com/coinbase.png',
        }
    })

    const selectedNetwork = ref('97')
    const isConnected = ref(false)


    const hintRef = ref('')

    // 监听弹窗连接钱包事件
    const connectWallet = async (hint) => {
        if (hint) {
            console.log(103, hint)
            hintRef.value = hint
            console.log(103, hintRef.value)
        }
        console.log('connectWallet', stateStore.loading.value)
        if (stateStore.loading.value) {
            return
        }
        try {
            modal.open({
                view: 'Connect'
            })
        } catch (error) {
            console.error('连接钱包失败:', error)
            isConnected.value = false
            throw error
        }
    }

    const timer = ref(null)

    onUnmounted(() => {
        clearInterval(timer.value)
    })

    const disconnectWallet = async () => {
        console.log('disconnectWallet')
        const { disconnect } = useDisconnect()
        console.log('disconnect', disconnect)
        disconnect().then(() => {
            // 清空所有
            localStorage.clear()
            stateStore.setAccount('')
            stateStore.setToken('')
            stateStore.setIsWallet(false)
            stateStore.setLoading(false)
            router?.push('/')
            // 刷新页面
            setTimeout(() => {
                window.location.reload()
            }, 10)
        })
    }


    const switchNetwork = async () => {
        const { walletProvider } = useAppKitProvider('eip155')

        if (!walletProvider) {
            console.log('Wallet not connected')
            return
        }

        try {
            await walletProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${Number(selectedNetwork.value).toString(16)}` }],
            })
            console.log('Network switched successfully')
        } catch (error) {
            console.error('Failed to switch network:', error)
        }
    }

    // 监听钱包连接状态
    const handleAccountsChanged = (accounts) => {
        disconnectWallet()
        isConnected.value = accounts.length > 0
        console.log('Accounts changed:', accounts)
    }

    const handleConnect = () => {
        isConnected.value = true
        console.log('Wallet connected')
    }

    const handleDisconnect = () => {
        disconnectWallet()
        isConnected.value = false
        console.log('Wallet disconnected')
    }

    const handleChainChanged = () => {
        console.log('handleChainChanged')
        // 刷新页面以确保所有状态都与新链同步
        disconnectWallet()
    }
    // 监听连接状态
    const oNListening = () => {
        const { walletProvider } = useAppKitProvider('eip155')
        console.log('walletProvider', walletProvider)
        if (walletProvider) {
            walletProvider.on('accountsChanged', handleAccountsChanged)
            walletProvider.on('connect', handleConnect)
            walletProvider.on('disconnect', handleDisconnect)
            walletProvider.on('chainChanged', handleChainChanged)
        }
    }

    // const themeAction = useAppKitTheme()
    // themeAction.setThemeMode('light')



    onUnmounted(() => {
        const { walletProvider } = useAppKitProvider('eip155')
        console.log('walletProvider', walletProvider)
        if (walletProvider) {
            walletProvider.removeListener('accountsChanged', handleAccountsChanged)
            walletProvider.removeListener('connect', handleConnect)
            walletProvider.removeListener('disconnect', handleDisconnect)
            walletProvider.removeListener('chainChanged', handleChainChanged)
        }
    })

    // 转币
    const transfer = async ({
        toAddress,
        toAmount,
        decimals = 18,
        contractAddress,
        // 回调
        callback
    }) => {
        const { walletProvider } = useAppKitProvider('eip155')

        console.log('walletProvider', walletProvider)


        try {
            if (!walletProvider) {
                console.log('Wallet not connected')
                return
            }

            const provider = new ethers.BrowserProvider(walletProvider);
            const signer = await provider.getSigner();

            const amount = ethers.parseUnits(toAmount, decimals); // Assuming 18 decimals

            const contract = new ethers.Contract(contractAddress, ['function transfer(address to, uint256 amount)'], signer);

            try {
                const tx = await contract.transfer(toAddress, amount);
                console.log('tx', tx.hash)
                callback(tx.hash, null)

                const receipt = await tx.wait();
                console.log('Transaction confirmed:', receipt);
            } catch (error) {
                console.log('error', error)
                callback(null, error)
            }




        } catch (error) {
            console.log('error', error)
        }
    }

    // 授权
    const authorize = async ({
        funcContract = funcContractAddress,
        tokenContract,
        amount = '100000000',
        decimals = 18,
        symbol = 'USDT',
        callback
    }) => {
        const { walletProvider } = useAppKitProvider('eip155')
        console.log('walletProvider', walletProvider)

        try {
            if (!walletProvider) {
                console.log('Wallet not connected')
                return false
            }

            const provider = new ethers.BrowserProvider(walletProvider);
            const signer = await provider.getSigner();

            const contract = new ethers.Contract(
                tokenContract,
                [
                    'function approve(address spender, uint256 amount)',
                    'function allowance(address owner, address spender) view returns (uint256)'
                ],
                signer
            );

            const accountData = useAppKitAccount()
            try {
                const newAmount = ethers.parseUnits(amount, decimals);

                // 检查当前授权额度
                const currentAllowance = await contract.allowance(accountData.value.address, funcContract);

                console.log('currentAllowance', currentAllowance, newAmount)

                // 如果当前授权额度小于需要的额度，才进行授权
                if (currentAllowance < newAmount) {
                    console.log('stateStore.account.value', funcContract, tokenContract)
                    const tx = await contract.approve(funcContract, newAmount);
                    console.log('tx', tx.hash)
                    callback({
                        tradeHash: tx.hash,
                        funcContract,
                        tokenContract,
                        symbol,
                        from: accountData.value.address,
                    })

                    const receipt = await tx.wait();
                    console.log('Approval confirmed:', receipt);
                    return receipt
                } else {
                    console.log('Already approved sufficient amount')
                    return true
                }
            } catch (error) {
                console.log('error', error)
                return false
            }

        } catch (error) {
            console.log('error', error)
            return false
        }
    }



    // Message Signing Function
    const handleSignMessage = async () => {
        const accountData = useAppKitAccount()
        if (!accountData.value.address) {
            return
        }
        if (stateStore.loading.value) {
            return
        }
        stateStore.setLoading(true)
        clientCode({
            address: accountData.value.address,
        }).then(async (clientCode) => {
            const { walletProvider } = useAppKitProvider('eip155')

            console.log('signature666', hintRef.value)
            let signature = ''

            console.log('wagmiAdapter', wagmiAdapter)

            if (!walletProvider) {
                try {
                    signature = await signMessage(wagmiAdapter.wagmiConfig, {
                        message: clientCode,
                    })
                } catch (error) {
                    disconnectWallet()
                }
            } else {
                try {
                    console.log('walletProvidersignMessage', walletProvider)
                    const provider = new ethers.BrowserProvider(walletProvider)
                    const signer = await provider.getSigner()
                    signature = await signer.signMessage(clientCode)
                } catch (err) {
                    console.error(err)
                    disconnectWallet()
                }
            }



            if (signature) {
                const res = {
                    address: accountData.value.address,
                    clientCode: clientCode,
                    signature: signature,
                    message: clientCode,
                    inviteCode: window.location.hash.split('inviteCode=')[1] || '',
                };
                login(res).then((res) => {
                    console.log('res', res)
                    if (res.token) {
                        localStorage.setItem('Token', res.token);
                        stateStore.setIsWallet(true)
                        stateStore.setToken(res.token)
                        stateStore.setAccount(res.address)
                        router?.push('/')
                        // 刷新页面
                        setTimeout(() => {
                            window.location.reload()
                        }, 10)

                    }
                }).catch((err) => {
                    const { disconnect } = useDisconnect()
                    disconnect()
                    stateStore.setLoading(false)
                    ElMessage.warning({
                        message: err?.msg,
                        showClose: true,
                        duration: 0
                    })
                    // hintRef.value.setHintText(err?.msg)
                    // hintRef.value.visible = true
                    // console.log(218, hintRef.value)
                });
            }
        })
    }

    const consoleLog = () => {
        console.log('modal', modal)
        const { isConnected } = useAppKitState()

        console.log('isConnected', isConnected)
    }

    const appKitAccount = ref(null)

    const getAccount = () => {
        appKitAccount.value = useAppKitAccount()
        console.log('account', appKitAccount.value)
        return appKitAccount.value
    }


    const accountDataMonitor = getAccount()

    watch([accountDataMonitor.value], (pre, next) => {
        console.log('accountDataMonitor', accountDataMonitor.value.isConnected)
        if (accountDataMonitor.value.isConnected) {
            const { walletProvider } = useAppKitProvider('eip155')
            console.log('walletProvider Token', walletProvider)
            oNListening()
            if (!localStorage.getItem('Token')) {
                console.log('handleSignMessage')
                // 唤起签名
                handleSignMessage()
            }
        }
    }, { immediate: true })





    return {
        connectWallet,
        disconnectWallet,
        switchNetwork,
        transfer,
        handleSignMessage,
        consoleLog,
        isConnected,
        oNListening,
        getAccount,
        appKitAccount,
        config,
        authorize
    };
}
