import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from '@/assets/image/myTeam/left.png';
import _imports_1 from '@/assets/image/myTeam/right.png';
const _hoisted_1 = {
  class: "tree-container"
};
const _hoisted_2 = {
  class: "node"
};
const _hoisted_3 = {
  class: "node-content"
};
const _hoisted_4 = {
  class: "user-info"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "amount-info"
};
const _hoisted_7 = {
  key: 1,
  class: "before-c"
};
import { ref, onMounted, watch } from 'vue';
import levelKey1 from '@/assets/image/rewards/table-img2.png';
import levelKey2 from '@/assets/image/rewards/table-img3.png';
import levelKey3 from '@/assets/image/rewards/table-img4.png';
import levelKey4 from '@/assets/image/rewards/table-img5.png';
import levelKey5 from '@/assets/image/rewards/table-img6.png';
import { thousandthConversion } from '@/utils/utils';
import useStateStore from '@/use/useStateStore';
export default {
  __name: 'MyThee',
  props: {
    node: {
      type: Object,
      default: () => ({
        id: '',
        label: '',
        parentId: null,
        key: '',
        value: '',
        describe: null,
        isLeaf: false,
        extend: {
          totalRechargeAmount: '0',
          todayRechargeAmount: '0'
        },
        children: [],
        totalRechargeAmount: '0',
        todayRechargeAmount: '0'
      })
    },
    renew: {
      type: Function,
      default: () => {}
    }
  },
  setup(__props) {
    const stateStore = useStateStore();
    const props = __props;
    const widthChildrenLeft = ref('0');
    const widthChildrenRight = ref('0');
    const offsetLeft = ref('0');
    const isHide = ref(false);
    const levelKeyImg = {
      1: levelKey1,
      2: levelKey2,
      3: levelKey3,
      4: levelKey4,
      5: levelKey5
    };
    const init = () => {
      props.renew();
      const left = document.querySelector(`[data-children="${props.node.id}"]`)?.querySelector('.tree-container')?.offsetWidth;
      const right = document.querySelector(`[data-children="${props.node.id}"]`)?.offsetWidth - document.querySelector(`[data-children="${props.node.id}"]`)?.querySelector('.tree-container')?.offsetWidth;
      const numLeft = right / (left + right) * 100;
      const numRight = left / (left + right) * 100;
      widthChildrenLeft.value = `${numLeft}%`;
      widthChildrenRight.value = `${numRight}%`;
      if (45 < numLeft && numLeft < 55) {
        offsetLeft.value = '25%';
      } else {
        offsetLeft.value = `${25 + (50 - numLeft) / 2}%`;
      }
    };
    onMounted(() => {
      init();
    });
    watch(isHide, () => {
      setTimeout(() => {
        init();
      }, 0);
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_MyTree = _resolveComponent("MyTree");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        src: levelKeyImg[__props.node.key],
        class: "avatar"
      }, null, 8, _hoisted_5), _createElementVNode("span", null, _toDisplayString(__props.node.label), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('今日の入金')) + ": " + _toDisplayString(_unref(thousandthConversion)(__props.node.todayRechargeAmount)) + " " + _toDisplayString(_unref(stateStore).coinSymbolArray.value[0]), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('累計入金')) + ": " + _toDisplayString(_unref(thousandthConversion)(__props.node.totalRechargeAmount)) + " " + _toDisplayString(_unref(stateStore).coinSymbolArray.value[0]), 1), __props.node.children && __props.node.children.length ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "h-btn",
        onClick: _cache[0] || (_cache[0] = $event => isHide.value = !isHide.value)
      }, [_createVNode(_component_svg_icon, {
        class: _normalizeClass(isHide.value ? 'rotate-180' : ''),
        "icon-class": "x"
      }, null, 8, ["class"])])) : _createCommentVNode("", true)])]), __props.node.children && __props.node.children.length && isHide.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref: `children-${__props.node.id}`,
        class: _normalizeClass(['children', __props.node.children.length > 1 ? 'children-box' : ''])
      }, [__props.node.children.length > 1 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle({
          left: offsetLeft.value
        }),
        class: "line-box"
      }, [_createElementVNode("div", {
        style: _normalizeStyle({
          width: widthChildrenLeft.value
        }),
        class: "thee-left"
      }, _cache[1] || (_cache[1] = [_createStaticVNode("<div class=\"img-box1\" data-v-2ca4057c><img class=\"thee-left-img1\" src=\"" + _imports_0 + "\" data-v-2ca4057c></div><div class=\"img-box2\" data-v-2ca4057c><img class=\"thee-left-img2\" src=\"" + _imports_0 + "\" data-v-2ca4057c></div><div class=\"line\" data-v-2ca4057c></div>", 3)]), 4), _createElementVNode("div", {
        style: _normalizeStyle({
          width: widthChildrenRight.value
        }),
        class: "thee-right"
      }, _cache[2] || (_cache[2] = [_createStaticVNode("<div class=\"img-box1\" data-v-2ca4057c><img class=\"thee-left-img1\" src=\"" + _imports_1 + "\" data-v-2ca4057c></div><div class=\"img-box2\" data-v-2ca4057c><img class=\"thee-left-img2\" src=\"" + _imports_1 + "\" data-v-2ca4057c></div><div class=\"line\" data-v-2ca4057c></div>", 3)]), 4)], 4)) : _createCommentVNode("", true), __props.node.children.length === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.node.children, child => {
        return _openBlock(), _createBlock(_component_MyTree, {
          key: child.id,
          node: child,
          renew: init
        }, null, 8, ["node"]);
      }), 128))], 2)) : _createCommentVNode("", true)])]);
    };
  }
};