
import request from './index';

// const key = 'https://www.metalifegame.org/api'; // 生产
// const key = ''; // 测试

// const api = http[key] || http.instance(key); // api modules.test

class Service {
  constructor() {
    this.api = request;
  }

  // eslint-disable-next-line class-methods-use-this
  buy(params) {
    return request.get('/api/buy', { params });
  }

  // post请求
  // eslint-disable-next-line class-methods-use-this
  postRequest(params) {
    return request.post('/api/url', params);
  }

  getByCode(params) { // 校验code
    return this.api.get('/agent/getByCode', { params });
  }

  agentRecordInit(params) {
    return this.api.post('/agentRecord/init', params);
  }

  agentRecordAdd(params) {
    return this.api.post('/agentRecord/add', params);
  }
  // 公共表格数据请求方法
  // publicGet(url, params) {
  //   return request.get(url, {
  //     params
  //   })
  // }

  // 公共post方法
  // publicPost(url, params) {
  //   return request.post(url, params)
  // }
}
export default new Service();


// 获取登录Code
export async function clientCode(params) {
  return request.get('/api/v1/account/getClientCode', params);
}

// 登录
export async function login(params, options) {
  return request.post('/api/v1/account/login', params, options);
}
// 退出登录
export async function logout(params) {
  return request.get('/api/v1/account/logout', params);
}

// 账户信息
export async function getAccountInfo(params) {
  return request.get('/api/v1/account/getAccountInfo', params);
}



// 查询投资配置
export async function investGetInvestConfig(params) {
  return request.get('/api/v1/invest/getInvestConfig', params);
}

// 充值(投资)
export async function investRecharge(params) {
  return request.post('/api/v1/invest/recharge', params);
}


// 提币
export async function investWithdrawal(params) {
  return request.post('/api/v1/invest/withdrawal', params);
}

// 分页查询充值(投资)记录
export async function getPageToRecharge(params) {
  return request.get('/api/v1/invest/getPageToRecharge', params);
}


// 分页查询提币记录
export async function getPageToWithdrawal(params) {
  return request.get('/api/v1/invest/getPageToWithdrawal', params);
}


// 分页查询投资利益记录
export async function getPageToIncome(params) {
  return request.get('/api/v1/invest/getPageToIncome', params);
}



// 获取团队成员信息
export async function getTeamMemberInfo(params) {
  return request.get('/api/v1/team/getTeamMemberInfo', params);
}

// 提币币种ID
export async function getCoinConfigInfo(params) {
  return request.get('/api/v1/invest/getCoinConfigInfo', params);
}

// 发送绑定邮箱邮件
export async function sendBindEmail(params) {
  return request.post('/api/v1/account/sendBindEmail', params);
}


// 绑定上级
export async function bindParent(params, options) {
  return request.post('/api/v1/account/bindParent', params, options);
}


// 查询投资收益数据
export async function getTotalInvestIncome(params) {
  return request.get('/api/v1/account/getTotalInvestIncome', params);
}


// 查询昨日投资收益信息
export async function getYesterdayInvestIncome(params) {
  return request.get('/api/v1/account/getYesterdayInvestIncome', params);
}


// 查询投资信息
export async function getInvestBoardInfo(params) {
  return request.get('/api/v1/invest/getInvestBoardInfo', params);
}


// 签到
export async function checkin(params) {
  return request.get('/api/v1/account/checkin', params);
}


// 查询投资信息
export async function getRechargeInfo(params) {
  return request.post('/api/v1/invest/getRechargeInfo', params);
}

// 查询投资看板
export async function getTeamBoard(params) {
  return request.get('/api/v1/team/getTeamBoard', params);
}


// 查询收益统计(柱状图)
export async function getIncomeStatistics(params) {
  return request.get('/api/v1/account/getIncomeStatistics', params);
}


// 分页查询通知(柱状图)
export async function getPageToNotify(params) {
  return request.get('/api/v1/account/getPageToNotify', params);
}
// 绑定邮箱回调
export async function bindEmailCallback(params) {
  return request.get('/api/v1/account/bindEmailCallback', params);
}

// 绑定邮箱信息
export async function getBindEmailInfo(params) {
  return request.get('/api/v1/account/getBindEmail', params);
}

// 查询收益倒计时
export function getIncomeCountdown(params) {
  const res = request.get('/api/v1/account/getIncomeCountdown', params);
  return res;
}

// 提交咨询
export async function submitConsult(params) {
  return request.post('/api/v1/contact/submitConsult', params);
}


// 校验充值
export function addressVerify(params) {
  const res = request.post('/api/v1/invest/verify', params);
  return res;
}


// 查询收益倒计时
export function getCoin(params) {
  const res = request.get('/api/v1/invest/getCoin', params);
  return res;
}

// 钱包授权
export function walletAuth(params) {
  const res = request.post('/api/v1/wallet/auth', params);
  return res;
}

// 获取授权列表
export function getAuthList(params) {
  const res = request.get('/api/v1/wallet/getAuthList', params);
  return res;
}