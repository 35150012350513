import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "template-page-four"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "text"
};
const _hoisted_4 = {
  class: "four-card"
};
import Card from '@/views/Home/components/card.vue';
export default {
  __name: 'FAQ',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createTextVNode(_toDisplayString(_ctx.$t('よくある')), 1), _createElementVNode("span", null, _toDisplayString(_ctx.$t('質問')), 1)]), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "btn-box"
      }, null, -1))]), _createElementVNode("div", _hoisted_4, [_createVNode(Card, {
        title: _ctx.$t('Barn to Earnの仕組みを自分でも取り組みたいです。教えてもらえますか？'),
        textArr: [_ctx.$t('はい、可能です。週に1回Barn to Earnの仕掛けで利益を出しているチームによるZOOM説明会があります。'), _ctx.$t('そこで実際にご自身で取り組む方法や実際に利益が出た結果報告が行われます。')]
      }, null, 8, ["title", "textArr"]), _createVNode(Card, {
        title: _ctx.$t('私は何もよくわかりません。それでも稼げますか？'),
        textArr: [_ctx.$t('稼げます。今流行であるミームコインの仕組みを利用して稼ぐ事が出来ているチームの仕掛けとなります。'), _ctx.$t('その為、めんどくさい作業をやりたくないと言う方は新E-NISAに投資をする事で利益を出す事ができます。')]
      }, null, 8, ["title", "textArr"]), _createVNode(Card, {
        title: _ctx.$t('新e-NISAの仕組みが凄いのは理解したのですが、本当に大丈夫ですか？'),
        textArr: [_ctx.$t('大丈夫です。ミームコインプラットフォームへの投資事業は2024年の夏からスタートした案件です。'), _ctx.$t('世界中の方々が利益を出している中、日本ではまだまだ取り組めている方が少ないのが実情です。'), _ctx.$t('海外チームで利益を出している手法をそのまま取り入れ、安定した収益を達成出来ます。')]
      }, null, 8, ["title", "textArr"]), _createVNode(Card, {
        title: _ctx.$t('元本は保証されていますか？'),
        textArr: [_ctx.$t('大変申し訳ありませんが、日本国でも取り組まれているNISAでも元本保証はございません。'), _ctx.$t('国で取り組んでいる案件で元本保証が無いにも関わらず、我々のチームでの保証をする事は出来ません。'), _ctx.$t('ただし、NISAコインの上場については2025年6月を予定しており、このコインにつきましては'), _ctx.$t('MEXCに上場させる事については保証させて頂きます。'), _ctx.$t('つまり、携わって頂く事で大きく利益を出して可能性が高いお話となります。')]
      }, null, 8, ["title", "textArr"])])]);
    };
  }
};